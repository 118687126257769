const RadioCircleFilledIcon = ({
	size = 'large',
	invertTheme = false,
	...props
}: {
	size: 'small' | 'large'
	invertTheme?: boolean
}) => (
	<svg
		width={size === 'small' ? 16 : 24}
		height={size === 'small' ? 16 : 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_294_6151)">
			<rect width="24" height="24" rx="12" fill="currentColor" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
				className={
					invertTheme
						? 'fill-uiTertiaryDark dark:fill-uiSecondaryLight'
						: 'fill-uiSecondaryLight dark:fill-uiTertiaryDark'
				}
				// fill="#FBFBFD"
			/>
		</g>
		<defs>
			<clipPath id="clip0_294_6151">
				<rect width="24" height="24" rx="12" fill="white" />
			</clipPath>
		</defs>
	</svg>
)

export default RadioCircleFilledIcon
